.getting-started-card {
    background-color: var(--tippy-card-black-bg);
    position: fixed;
    z-index: 6;
    right: 20px;
    top: 54px;

}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--tippy-card-black-bg);
  right: 64px;
  position: fixed;
  top: 42px
}

.getting_tippy__position {
  position: fixed;
}