.compound-list {
    height: 64px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    align-items: center;
    background: white;
    grid-column-gap: 8px;
    cursor: pointer;
    .toggle__switch {
        width: 32px;
        margin-left: auto;
        margin-bottom: 0;
    }
    .list__title {
        font-size: 14px;
        color: var(--N900);
    }
    .list__subtitle {
        color: --N400;
        font-size: 12px;
    }
    .tag {
        height: 20px;
        border-radius: 2px;
        border: solid 1px #b5d3f2;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, #f0f7ff, #f0f7ff);
        color: var(--B500);
        font-size: 11px;
        font-weight: 600;
        padding: 0 6px;
        margin-left: 8px;
        line-height: 23px;
    }
}