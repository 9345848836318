:root {
    --V500: #664bee;
    --V200: #b6abf3;
    --V100: #f3f0ff;
    --B700: #003974;
    --B600: #004fa1;
    --B500: #0066cc;
    --B400: #3989d9;
    --B300: #72ace6;
    --B200: #abcff3;
    --B100: #e5f2ff;
    --N900: #000a14;
    --N800: #1d2730;
    --N700: #3b444c;
    --N600: #596168;
    --N500: #767d84;
    --N50: #f7fafc;
    --N400: #959ba1;
    --N300: #b1b7bc;
    --N200: #d0d4d9;
    --N100: #edf1f5;
    --N000: #ffffff;
    --N0: #ffffff;
    --G700: #106240;
    --G600: #168859;
    --G500: #1dad70;
    --G400: #50c191;
    --G300: #83d5b2;
    --G200: #b6e9d3;
    --G100: #e9fbf4;
    --Y700: #8c6324;
    --Y600: #c58d36;
    --Y500: #ffb549;
    --Y400: #ffc570;
    --Y300: #ffd597;
    --Y200: #ffe5be;
    --Y100: #fff5e5;
    --R700: #862020;
    --R600: #bd2f2f;
    --R500: #f33e3e;
    --R400: #f66868;
    --R300: #f99292;
    --R200: #fcbcbc;
    --R100: #fde7e7;
    --O500: #ff7e5b;
    --yellow: #ff8300;
    --red: rgba(240, 96, 96, 1);
    --green: rgba(23, 170, 86, 1);
    --orange: rgba(252, 173, 38, 1);
    --window-bg: #f2f4f7;
    --white: #fff;
    --gray: #1e23601a;
    --code-editor-green: #eaf1dd;
    --code-editor-red: #ffd4d1;
    --code-diff-dot: #F37171;
    --tippy-card-black-bg: #2d3452;
    --terminal-bg: #0b0f22;
    --inprogress-orange: #ff7e5b;
}
