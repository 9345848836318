.radio-group {
    .label,
    .radio,
    .checkbox {
        margin: 0;
    }
    height: 30px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    .radio {
        height: 100%;
        width: auto;
        color: var(--N400);
        display: flex;
        align-items: center;
    }
    .radio+.radio {
        margin-top: 0;
    }
    input[type=checkbox] {
        display: contents;
        overflow: hidden;
        max-width: 0px;
        height: 0;
        opacity: 0;
        margin: unset;
    }
    input+.radio__item-label {
        padding: 4px 6px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        transition: all 0.5s;
        cursor: pointer;
    }
    input[type=checkbox]:checked+.radio__item-label {
        background: #2c3354;
        color: white;
        border-radius: 4px;
    }
}