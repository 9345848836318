.pagination-wrapper {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    justify-content: space-between;
    margin: 0px;
    background-color: white;
}

.pagination {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0px;
}

.select {
    display: flex;
    align-items: center;
    position: relative;
}

.select__placeholder {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--N700);
    margin-right: 8px;
}

.pagination__select-menu {
    padding: 0px;
    list-style: none;
    overflow: hidden;
    border: solid 1px var(--N200);
    border-radius: 4px;
    width: 64px;
    position: absolute;
    bottom: 31px;
    background-color: white;
}

.select__button {
    width: 64px;
    background-color: transparent;
    border-radius: 4px;
    border: solid 1px var(--N200);
    padding: 5px 10px;
    text-align: left;
}

.select__item {
    padding: 5px 10px;
    cursor: pointer;
}

.select__icon {
    margin-left: 18px;
}

.page {
    margin: 0px 8px;
    display: inline-block;
}

.page-number {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--N700);
}

.page__button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--B500);
    background-color: var(--white);
    padding: 0px;
    border: none;
    user-select: none;
}

.page__button--icon {
    background-color: var(--white);
    color: var(--B500);
}


/* MODIFIERS */

.page__button--selected {
    color: var(--white);
    background-color: var(--B500);
}


/* States and Selectorys */

.page__button:disabled {
    opacity: 0.5;
}

.select__item:hover {
    background-color: #f2f2f2;
}

.page__button:hover~:disabled {
    background-color: var(--B100);
}

.page__button--selected:hover {
    color: var(--white);
    background-color: var(--B500);
}

@media(min-width: 1024px) {
    .pagination {
        margin: 16px auto;
    }
}