.toggle__switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &.disabled{
        cursor: not-allowed;
        .toggle__slider{
            cursor: not-allowed;
        }
    }
}


/* Hide default HTML checkbox */

.toggle__switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.toggle__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: all .4s ease;
}

.toggle__slider:before {
    position: absolute;
    content: "";
    height: calc( 100% - 8px);
    width: calc( 50% - 4px);
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: all 0.4s ease;
}

input:checked+.toggle__slider {
    background-color: var(--color);
}

input:focus+.toggle__slider {
    box-shadow: 0 0 1px var(--color);
}

input:checked+.toggle__slider:before {
    transform: translateX(calc( 100%));
}


/* Rounded sliders */

.toggle__slider.round {
    border-radius: 34px;
}

.toggle__slider.round:before {
    border-radius: 50%;
}