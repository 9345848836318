.select-button__arrow {
    position: absolute;
    right: 5px;
}

.select-button {
    border-radius: 4px;
    justify-content: space-between;
}

.select-button.default {
    height: 40px;
    padding: 12px 10px;
    background-color: white;
}

.select-button:disabled {
    background-color: var(--N100);
}

.select-button--default {
    padding: 8px 8px 8px 10px;
    width: 100%;
    font-weight: 500;
    background-color: var(--N000);
}

.select__label-container {
    display: inline-block;
    margin-left: auto;
}

.select__options-container {
    max-height: 220px;
    overflow: auto;
    color: var(--N900);
}

.select__option-with-subtitle {
    padding: 9px 12px;
    margin-top: 2px;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.select__option-with-subtitle--empty-state {
    color: var(--N500);
}

.select-popup {
    overflow: auto;
    max-height: 220px;
    overflow: auto;
    padding: 8px 0px;
}

.select__option {
    padding: 10px 12px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background-color: var(--N000);
}

.select__option.selected {
    color: var(--B500);
    background-color: var(--B100);
}

.select-button__selected-option {
    display: inline-block;
    white-space: nowrap;
    text-align: left;
}

.select__check-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.option-with-subtitle__subtitle {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #404040;
}

.option-group__label {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--N400);
    text-transform: uppercase;
}

.select__sticky-bottom {
    position: sticky;
    bottom: -8px;
    margin-bottom: -8px;
    box-shadow: 0 -1px 0 0 var(--N200);
    background-color: inherit;
    padding: 11px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--B500);
    font-size: 13px;
}

.select__sticky-bottom svg path {
    fill: var(--B500);
}

.select__all {
    height: 36px;
    padding: 4px 12px 4px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background-color: #f5f5f5;
}

.popup-button:disabled .select-button {
    background-color: var(--N100);
}

.select__option-with-subtitle:hover,
.select__option:hover {
    background-color: var(--window-bg);
}

.select__option-with-subtitle--empty-state:hover {
    background-color: unset;
}
