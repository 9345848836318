.modal-body--scan-details {
    position: fixed;
    top: 0;
    right: 0;
    background: var(--N000);
    width: 800px;
}

.security-tab__table {
    width: 100%;
}

.security-tab__table-header {
    border-bottom: solid 1px var(--N200);
}

.security-cell-header {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: uppercase;
    color: var(--N500);
}

.dc__security-tab__table-row {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: var(--N900);
    box-shadow: inset 0 -1px 0 0 #f5f5f5;
}

.security-tab__cell-cve,
.security-tab__cell-severity,
.security-tab__cell-package,
.security-tab__cell-current-ver,
.security-tab__cell-fixed-ver,
.security-tab__cell-policy {
    padding: 10px;
}

.security-tab__cell-cve {
    padding-left: 0px;
    text-align: left;
    width: 20%;
}

.security-tab__cell-policy {
    padding-right: 0px;
}

.security-tab__cell-policy--whitelist,
.security-tab__cell-policy--whitelisted {
    color: var(--G500);
    text-transform: capitalize;
}

.security-tab__cell-policy--block {
    color: var(--R500);
    text-transform: capitalize;
}

.scanned-object__results {
    margin: 0 20px;
}